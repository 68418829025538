<template lang="html">
  <div class="all">
    <FrqModal :is-show.sync=showLegend></FrqModal>
    <h3>Ad Set</h3>
    <form @submit.prevent='submit'>
      <div class="all">
        <div class="field-container">
          <label>Location</label>
          <cool-select :disabled='!userHasPermission("smartlinks.edit")' :isDisabled='true' v-model="geoSelected"
            :items="geosSelect" />
          <ul class='selected-items' v-show='form.geo.length'>
            <template v-if='userHasPermission("smartlinks.edit")'>
              <li @click='userHasPermission("smartlinks.edit") ? onGeoDel(index) : e=>{}'
                v-for='(geo, index) in form.geo'>
                <i class="fa fa-times del-x" aria-hidden="true"></i>
                <span>
                  {{geo}}
                </span>
              </li>
            </template>
            <template v-else>
              <li v-for='(region, index) in form.region'>
                <span>
                  {{region}}
                </span>
              </li>
            </template>
          </ul>
          <label :for='html.geo1'>Include</label>
          <input :disabled='!userHasPermission("smartlinks.edit")' :id='html.geo1' v-model='fieldsIn.geo' type="radio"
            :value='true'>
          <label :for='html.geo2'>Exclude</label>
          <input :disabled='!userHasPermission("smartlinks.edit")' :id='html.geo2' v-model='fieldsIn.geo' type="radio"
            :value='false'>
        </div>
        <div class="field-container">
          <label>Region</label>
          <cool-select :disabled='!userHasPermission("smartlinks.edit")' :isDisabled='true' v-model="regionsSelected"
            :items="regionsSelect" />
          <ul class='selected-items' v-show='form.region.length'>
            <template v-if='userHasPermission("smartlinks.edit")'>
              <li @click='onRegionDel(index)' v-for='(region, index) in form.region'>
                <i class="fa fa-times del-x" aria-hidden="true"></i>
                <span>
                  {{region}}
                </span>
              </li>
            </template>
            <template v-else>
              <li v-for='(region, index) in form.region'>
                <span>
                  {{region}}
                </span>
              </li>
            </template>
          </ul>
          <label :for='html.region1'>Include</label>
          <input :disabled='!userHasPermission("smartlinks.edit")' :id='html.region1' v-model='fieldsIn.region'
            type="radio" :value='true'>
          <label :for='html.region2'>Exclude</label>
          <input :disabled='!userHasPermission("smartlinks.edit")' :id='html.region2' v-model='fieldsIn.region'
            type="radio" :value='false'>
        </div>
        <div class="field-container">
          <label>OS</label>
          <select :disabled='!userHasPermission("smartlinks.edit")' class="def" v-model='form.os'>
            <option value="">All</option>
            <option value="iOS">iOS</option>
            <option value="Android">Android</option>
          </select>
        </div>

        <div class="field-container">
            <label>Frequency</label>
            <section v-for='i in [0,24,12,6,3,1]'>
                <label :for='"pl-frq-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' :id='"pl-frq-hours-"+i' type="radio" v-model.number='form.frq' :value='i'>
            </section>
        </div>

        <div class="field-container">
            <label>IP Frequency</label>

            <section v-for='i in [0,24,12,6,3,1]'>
                <label :for='"pl-ip-frq-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' :id='"pl-ip-frq-hours-"+i' type="radio" v-model.number='form.ip_frq' :value='i'>
            </section>
        </div>

        <div class="field-container">
          <label>IP Frequency Level:</label>
          <select v-model='form.ip_frq_level'>
            <option value="l1">Level 1</option>
            <option value="l2">Level 2</option>
            <option value="l3">Level 3</option>
            <option value="l4">Level 4</option>
            <option value="l5">Level 5</option>
          </select>
          <div>
            <b-icon style="cursor: pointer;" @click="showLegend = !showLegend" icon="question-circle-fill" aria-label="Help"></b-icon>
          </div>
        </div>

        <div class="field-container">
          <label>Clicks Cap(0=infinite)</label>
          <input type="text" class="def input-c-141" v-model="form.cap"/>
        </div>


        <div class="field-container">
          <label>Connection Type</label>
          <select class="def" v-model="form.connection_type" @change="onConnectionTypeChange($event.target.value)">
            <option v-for="type in connectionTypes" :key="type" :value="type">
              {{ type }}
            </option>
          </select>
        </div>

        <div  v-if='form.geo != null && form.geo.length > 0 && form.connection_type == "3G"'  class="field-container">
          <label>Mobile Carrier</label>
            <cool-select v-if="isps" v-model="carrierSelected" :items="isps" />
            <ul class='selected-items' v-if="form.isps">
                <template v-if='userHasPermission("smartlinks.edit")'>
                    <li @click='onCarrierDel(index)' v-for='(isps, index) in form.isps'>
                        <i class="fa fa-times del-x" aria-hidden="true"></i>
                        <span>
                            {{isps}}
                        </span>
                    </li>
                </template>
                <template v-else>
                    <li v-for='(isps, index) in form.isps'>
                        <span>
                            {{isps}}
                        </span>
                    </li>
                </template>
            </ul>
            <div class="isp-checkbox">
                <input type="checkbox" v-model="form.isp_bl" name="BL" >
                <label for="scales">Blacklist</label>
            </div>
        </div>

        <div class="field-container">
          <label>Bundles List:</label>
          <div class="cb_bundles_class">
            <input type="checkbox" @click="bundlesCbClick" :checked="show_bundles_lists"/> Use Bundles List
          </div>
          <select v-model='form.bundles_lists_id' :disabled="! isAdmin" v-if="show_bundles_lists">
            <option value="0">No Bundle List</option>
            <option v-for="item of bundlesLists" :value="item.id">{{ item.list_name }}</option>
          </select>
        </div>

        <div class="field-container">
          <label>Comments</label>
          <input type="text" class="def input-c-141" v-model='form.comments'>
        </div>

        <div class="field-container">
          <label>Offer Rotation:</label>
          <div class="cb_bundles_class">
            <input type="checkbox" @click="offerRotationCbClick" :checked="form.is_offers_rotation"/> Use Offer Rotation
          </div>
          <div v-if="form.is_offers_rotation">
            <input type="number" class="def input-c-141" style="width: 200px;" v-model.number="form.number_of_hours"> (every X hour, on each X:00)
          </div>
        </div>

        <div class="field-container" v-if="form.current_selected_offer !== 0 && form.is_offers_rotation === true">
          <div>
            Last Rotate: {{ form.time_of_rotation }}
          </div>
          <div>
            <div style="display: inline-block;">
              Currently Selected Offer: {{ form.current_selected_offer }}
            </div>
            
            <div style="padding-left: 30px; display: inline-block;">
              <button type="button" @click="rotate_offer_now" class="btn btn-sm">Rotate Now</button>
            </div>
            
          </div>
        </div>      
        <div v-if="form.current_selected_offer !== 0 && form.is_offers_rotation === true && Object.keys(offers_rotation_data).length > 1">
          <ul>
            <li v-for="(time, offer_name) in offers_rotation_data">
              <span :class="form.time_of_rotation.includes(time) ? 'bold' : ''">{{ offer_name }}</span> <span v-if="!form.time_of_rotation.includes(time)"> {{'-'}}</span> <span v-if="!form.time_of_rotation.includes(time)">{{ time }}</span>
            </li>
          </ul>
        </div>    
        <div class="field-container" v-if="form.current_selected_offer == 0 && form.is_offers_rotation === true && !first_rotate">
          <div>
            All Offers Are Excluded.
          </div>
        </div>

        <template v-if='form.id && campaign && !campaign.is_click_sl && form.is_vta'>
          <div class="field-container">
            <label>Click CTR %</label>
            <input :disabled='!userHasPermission("smartlinks.edit")' min="0" max="100" step="0.01"
              class='form-control pub-id-input' type="number" v-model.number='form.click_ctr'>
          </div>
          <div class="field-container">
            <label>Click Offer Name</label>
            <input :disabled='!userHasPermission("smartlinks.edit")' class='form-control pub-id-input'
              v-model.trim='form.name'>
          </div>

          <div class="field-container">
            <label>Click Affise ID</label>
            <input :disabled='!userHasPermission("smartlinks.edit")' class='form-control pub-id-input' type="number"
              v-model.number='form.affiseID'>
          </div>

        </template>

        <div class="pl-btns-1">
          <button v-if='userHasPermission("smartlinks.edit")' type="submit"
            class="btn btn-success">{{data ? 'Save Ad Set Changes' : 'Create Ad Set'}}</button>
          <button v-if='data && userHasPermission("smartlinks.edit")' type="button" @click='onDeleteClick'
            class="btn btn-danger">Delete Ad Set</button>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
  import moment from 'moment'
  import countries from '@/assets/countries.js'
  import regions from '@/assets/us_states.js'
  import ClickOffer from './ClickOffer'
  import {CoolSelect} from "vue-cool-select";
  import FrqModal from '../../../../components/FrqModal.vue';
  export default {
    props: {
      data: {},
      onSubmit: {},
      onDelete: {},
    },
    components: {
      ClickOffer,
      CoolSelect,
      FrqModal
    },
    data() {
      return {
        geoSelected: null,
        regionsSelected: null,
        offers: [],
        all_geos: countries,
        all_regions: regions,
        all_os: [
          "iOS", "Android"
        ],
        form: {
          frq: 0,
          ip_frq: 0,
          comments: "",
          is_vta: true,
          cap: '0',
          bundles_lists_id: 0,
          is_offers_rotation: 0,
          number_of_hours: 1,
        },
        fieldsIn: null,
        html: {
          geo1: this.getHtmlId(),
          geo2: this.getHtmlId(),
          region1: this.getHtmlId(),
          region2: this.getHtmlId(),
          os1: this.getHtmlId(),
          os2: this.getHtmlId()
        },
        displayClickOfferModal: true,
        clickOffer: null,
        showLegend: false,
        bundlesLists: [],
        isAdmin: false,
        show_bundles_lists: false,
        show_offer_rotation: false,
        rotate_offer_each_hour: 1,
        first_rotate: false,
        offers_rotation_data: {},
        all_isps: null,
        carrierSelected: null,
        connectionTypes: ["all", "wifi", "3G"],
      }
    },
    created() {
      let data = this.data || {}
      this.fieldsIn = data.fieldsIn || {
        geo: true,
        region: true
      }
      let placementDeepCopy = JSON.parse(JSON.stringify(data))
      this.form = Object.assign({
        geo: [],
        frq:0,
        cap:'0',
        ip_frq:0,
        os: "",
        region: [],
        offers: [],
        ip_frq_level: 'l3',
        bundles_lists_id: 0,
        is_offers_rotation: 0,
        number_of_hours: 1,
        isps: [],
        isp_bl: 0,
        connection_type: 'all',
      }, placementDeepCopy)
      this.form.number_of_hours = this.form.number_of_hours != 0 ? this.form.number_of_hours : 1
      const permList = this.$store.state.user.perms
      if (Object.values(permList).indexOf('bundles.assoc') > -1) {
          this.isAdmin = true
      }

      this.getBundlesLists()

      if (this.form.is_offers_rotation) {
        this.updateOffersRotationData();
      }
    },
    watch: {
      ['form.cap'](newValue) {
          if (typeof newValue !== 'undefined' &&  newValue !== null){
            const result = newValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.$set(this.form,'cap' ,result);
          }
        }, 
      geoSelected(value) {
        if (value != null) {
          this.onGeoAdd(value)
        }
      },
      regionsSelected(value) {
        if (value != null) {
          this.onRegionAdd(value)
        }
      },
      carrierSelected(value) {
        if (value != null) {
            if (this.form.isps == null) {
                this.form.isps = []
            }
            this.form.isps.push(value)
        }
      },
      async ['form.geo'](val) {
        await this.fetchIsps()
      }

    },
    methods: {
      updateOffersRotationData() {
        // get included split to array
        // get the current selected index
        // return a data object 
        let offerid_to_name = {};
        for (const k in this.form.offers) {
          offerid_to_name[this.form.offers[k].id] = this.form.offers[k].name + ' | ' + this.form.offers[k].id;
          if (this.form.offers[k].click_offer !== null) {
            offerid_to_name[this.form.offers[k].id] = this.form.offers[k].click_offer.name + ' | ' + this.form.offers[k].id;
          }
        }
        console.log(offerid_to_name);
        let included_offers_arr = this.form.included_offers_ids.split(',');
        let rotation_index = included_offers_arr.indexOf(`${this.form.current_selected_offer}`);

        let i = 0;
        let offername_to_rotation_time = {};
        for(var index = rotation_index, length=included_offers_arr.length; index<length; index++) { 
          console.log(index, included_offers_arr[index], offerid_to_name[included_offers_arr[index]]);

          offername_to_rotation_time[offerid_to_name[included_offers_arr[index]]] = i * this.form.number_of_hours;
          index == (included_offers_arr.length-1) && (index=-1,length=rotation_index);
          i++;
        }

        let utc_time = moment().toDate().toUTCString()
        let offername_to_rorion_hour = {};

        for (const k in offername_to_rotation_time) {
          if (offername_to_rotation_time[k] !== 0) {
            offername_to_rorion_hour[k] = moment(utc_time).add(offername_to_rotation_time[k], 'hours').utc().format('HH:00');
          } else {
            offername_to_rorion_hour[k] = '';
          }
        }

        this.offers_rotation_data = offername_to_rorion_hour;
      },
      onDeleteClick() {
        let c = window.confirm("Are you sure that you wish to delete this placement?")
        if (!c) {
          return
        }
        this.onDelete()
      },
      onGeoAdd(v) {
        var short = this.geos.find(itm => {
          if (itm.label === v)
            return (itm.val);
        });
        this.form.geo = [short.val]
      },
      onGeoDel(index) {
        this.form.geo.splice(index, 1)
      },
      onRegionAdd(v) {
        var short = this.regions.filter(itm => {
          if (itm.label === v)
            return (itm.val);
        });
        this.form.region.push(short[0].val)
      },
      onRegionDel(index) {
        this.form.region.splice(index, 1)
      },
      onOsAdd(v) {
        this.form.os.push(v)
      },
      onOsDel(index) {
        this.form.os.splice(index, 1)
      },
      submit() {
        if (!this.userHasPermission("smartlinks.edit")) {
          return
        }
        let form = Object.assign({}, this.form)
        form.offers = form.offers.map(v => {
          let d = Object.assign({}, v)
          delete d.offers
          return d
        })



        if (this.form.is_offers_rotation) {
          if (parseInt(this.form.number_of_hours) < 1 || this.form.number_of_hours === '') {
            this.notifyError('Offer Rotation must be number bigger then 1.');
            return;
          }
        } else {
          this.form.number_of_hours = 1;
        }

        if (this.carrierSelected) {
          this.carrierSelected = null
        }

        ["geo", "region"].forEach(field => {
          let notIn = !this.fieldsIn[field]
          form[field] = (notIn && form[field].length > 0 ? '!' : '') + form[field].join(',')
        })
       
        let cap = this.form.cap.replace(/[,]*/g, '')
        form.cap = parseInt(cap);

        if (form.connection_type === 'wifi') {
          form.isps = [];
        }
        
        if (typeof form.connection_type !== 'string' || form.connection_type === '') {
          form.connection_type = 'All'
        }

        this.form.bundles_lists_id = parseInt(this.form.bundles_lists_id)
        this.onSubmit(form) && this.form.nullify()
      },
      async getBundlesLists() {
        const data = await this.$http.post(this.resources.Management.getBundlesLists, {})
        this.bundlesLists = data.body.data
        if (this.form.bundles_lists_id !== 0) {
          this.show_bundles_lists = true
        }
        this.bundlesLists.sort((a, b) => a.list_name.localeCompare(b.list_name))
      },
      bundlesCbClick(e) {
        if (e.target.checked) {
          this.show_bundles_lists = true
        } else {
          this.form.bundles_lists_id = 0
          this.show_bundles_lists = false
        }
      },
      offerRotationCbClick(e) {
        if (e.target.checked) {
          this.form.is_offers_rotation = true
          this.first_rotate = true
        } else {
          this.form.is_offers_rotation = false
          this.first_rotate = false
        }
      },
      async rotate_offer_now() {
        const data = await this.$http.post(this.resources.Management.rotateOfferNow, {
          adset_id: this.form.id
        })
        this.form.time_of_rotation = data.body.time_of_rotation
        this.form.current_selected_offer = data.body.current_selected_offer
        // refresh page 
        
        this.notifySuccess('Offer rotated. Refresh to update table and weights.')
        location.reload();
      },
      async fetchIsps() {
        try {
          const r = await this.$http.get(this.resources.SmartLinksCampaigns.getISPs, {
            params: {
              countries: this.form.geo
            }
          })
          const isps = await r.json()
          this.all_isps = isps
        } catch (err) {
          this.notifyError("can't fetch isps")
        }
      },
      onCarrierDel(index) {
        this.form.isps.splice(index, 1)
      },
      onConnectionTypeChange(value) {
        if (value === 'wifi' || value === 'all') {
          this.form.isps = [];
          this.form.isp_bl = 0;
        }
      },
    },
    computed: {
      campaign() {
        let {
          campId
        } = this.$route.params
        return this.$store.state.smartlinks.campaigns.find(v => v.id == campId)
      },
      geosSelect() {
        let a = []
        Object.keys(this.all_geos).filter(v => !this.form.geo.includes(v.code))
          .forEach(code => {
            let name = this.all_geos[code]
            a.push(name)
          })
        return a
      },
      geos() {
        let a = []
        Object.keys(this.all_geos).filter(v => !this.form.geo.includes(v.code))
          .forEach(code => {
            let name = this.all_geos[code]
            a.push({
              label: name,
              val: code
            })
          })
        return a
      },
      regionsSelect() {
        let a = []
        Object.keys(this.all_regions).filter(v => !this.form.region.includes(v.code))
          .forEach(code => {
            let name = this.all_regions[code]
            a.push(name)
          })
        return a
      },
      regions() {
        let a = []
        Object.keys(this.all_regions).filter(v => !this.form.region.includes(v.code))
          .forEach(code => {
            let name = this.all_regions[code]
            a.push({
              label: name,
              val: code
            })
          })
        return a
      },
      os() {
        return this.all_os.filter(v => !this.form.os.includes(v))
      },
      isEdit() {
        return !!this.data
      },
      isps() {
        return this.all_isps
      },
    }
  }
</script>

<style lang="css" scoped>
  .select {
    max-width: 400px;
  }

  .selected-items {
    background: #f1f0f0;
    padding: 2px 6px;
    border: 1px solid gainsboro;
    border-radius: 4px;
    max-width: 196px;
    display: flex;
    flex-wrap: wrap;
  }

  .selected-items>li {
    margin-right: 3px;
    padding: 2px 7px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }

  .selected-items>li:hover {
    background: #dfdfdf;
    cursor: pointer;
  }

  .search-offers-input {
    background: #fafafa;
  }

  .search-offers-input:focus {
    background: white;
  }

  .pl-btns-1 {
    display: flex;
    justify-content: space-between;
  }

  .input-c-141 {
    width: 600px;
    font-size: 14px;
    padding: 11px 13px;
  }

  .c-o-251 {
    margin: 20px 0;
  }

  .bold {
    font-weight: bolder;
  }
  .isp-checkbox{
    padding-left: 20px;
  }
</style>